<template>
  <b-card>
    <b-modal hide-footer centered size="lg" v-model="modalTemplate">
      <b-card centered title="Errores">
        <b-row>
          <b-col md="12" class="mt-1">
            <b-table responsive="sm" :items="tempResult" striped hover> </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <b-modal hide-footer centered size="lg" v-model="modalTemplate">
      <b-card centered title="Errores">
        <b-row>
          <b-col md="12" class="mt-1">
            <b-table responsive="sm" :items="tempResult" striped hover> </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <fab :bg-color="bgColor" :actions="fabActions" @translate="translate" @savePage="savePage"></fab>
    <validation-observer ref="simpleRules" #default="{ invalid }">
      <b-form>
        <b-tabs align="center" nav-wrapper-class="sticky-top bg-light">
          <b-tab v-for="(cont, index) in page.content" :key="index" :title="cont.block">
            <fieldSelector
              v-if="cont.block === 'es'"
              :hidden="$session.user.role === 'EDITOR'"
              ref="fieldSelector"
              :pageName="pageName"
              @addField="addField"
            ></fieldSelector>

            <hr class="solid" />

            <smartComponent
              :pageName="pageName"
              :content="myContent(cont.block)"
              :updatedAt="page.updatedAt"
              @deleteField="deleteField"
            ></smartComponent>
          </b-tab>
          <b-tab title="Imagenes"> <page-images :pageName="pageName"></page-images> </b-tab>
        </b-tabs>
        <!-- publish button -->
        <b-row>
          <b-col md="6" class="mt-1">
            <b-button :disabled="invalid || $apiCall.state || page.content.length === 0" block variant="primary" type="button" @click="savePage">
              Guardar "{{ pageName }}"
            </b-button>
          </b-col>
          <!-- <b-col md="6" class="mt-1">
            <b-button :disabled="$apiCall.state" block variant="outline-info" type="button" @click="reset"> Reset </b-button>
          </b-col> -->
          <b-col md="6" class="mt-1">
            <b-button :disabled="$apiCall.state" block variant="outline-info" type="button" @click="translate"> Traducir </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard, BForm, BRow, BCol, BButton, BTabs, BTab, BModal, BTable } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import usePageAPI from '@/views/components/pageAPI/page'
import fab from 'vue-fab'

localize('es')

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    BModal,
    BTable,
    fab,

    ValidationProvider,
    ValidationObserver,

    pageImages: () => import('@/views/components/pageAPI/pageImages.vue' /* webpackChunkName: "pageImages" */),
    smartComponent: () => import('@/views/components/pageAPI/smartComponent.vue' /* webpackChunkName: "smartComponent" */),
    fieldSelector: () => import('@/views/components/pageAPI/fieldSelector.vue' /* webpackChunkName: "fieldSelector" */)
  },
  props: {
    pageName: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      // validations
      required,
      modalTemplate: false,
      tempResult: [],

      bgColor: '#00cfe8',
      fabActions: [
        {
          name: 'savePage',
          icon: 'save'
        },
        {
          name: 'translate',
          icon: 'translate'
        }
      ]
    }
  },
  setup() {
    const { publish } = usePageAPI()

    return { publish }
  },

  computed: {
    lang() {
      return this.$store.state.storyStore.story.lang
    },
    story() {
      return this.$store.state.storyStore.story
    },
    page() {
      return this.$store.getters['storyStore/getPage'](this.pageName)
    },
    myContent() {
      return block => this.page.content.find(cont => cont.block === block)
    }
  },
  methods: {
    addField({ inputSelected, field }) {
      // valida si existe el campo
      const campoRepetido =
        this.page.content.length > 0
          ? this.page.content[0].element?.reduce((res, el) => {
              if (el.field === field) res = true

              return res
            }, false)
          : false

      if (campoRepetido) return this.$noti.warn(`El campo está duplicado`)

      // agrega los campos
      if (this.page.content.length === 0)
        this.page.content = this.lang.map(l => ({
          autoTranslate: cont.autoTranslate,
          block: l,
          element: [
            {
              field,
              type: inputSelected,
              ...(inputSelected !== 'GROUP' && { value: '' }),
              ...(inputSelected === 'GROUP' && { groupValue: [] })
            }
          ]
        }))
      else
        this.page.content = this.page.content.map(cont => ({
          autoTranslate: cont.autoTranslate,
          block: cont.block,
          element: [
            ...cont.element,
            {
              field,
              type: inputSelected,
              ...(inputSelected !== 'GROUP' && { value: '' }),
              ...(inputSelected === 'GROUP' && { groupValue: [] })
            }
          ]
        }))

      // limpia campos
      this.$refs.fieldSelector[0].reset()
    },
    deleteField(fi) {
      this.page.content = this.page.content.map(ct => {
        const delElem = ct.element.filter(({ field }) => field !== fi)
        ct.element = delElem
        return ct
      })
    },

    testTemplate() {
      const template = JSON.parse(this.story.template)

      return template.reduce((acc, page) => {
        if (page.name !== this.page.name) return acc

        const sp = this.page
        const el = page.content.element.reduce((ecc, el) => {
          if (!sp) acc.push({ page: page.name, field: '', type: '', error: 'Página Requerida' })
          else {
            const { element } = sp.content.find(pp => pp.block === 'es')
            const diff = element.reduce((err, ef) => {
              if (ef.field === el.field && ef.type === el.type) {
                err = true
                if (el.type === 'GROUP') {
                  if (el.groupValue)
                    el.groupValue.forEach(gve => {
                      const gvfield = !ef.groupValue
                        ? false
                        : ef.groupValue.reduce((eeg, rees, i) => {
                            let existe = false
                            rees.forEach(gg => {
                              if (gve.field === 'IMG' || (gg.field === gve.field && gg.type === gve.type)) existe = true
                            })
                            if (!existe)
                              eeg.push({ page: page.name, field: `${el.field}[ ${i + 1} ]↔️${gve.field}`, type: gve.type, error: 'Requerido' })

                            return eeg
                          }, [])

                      if (gvfield.length > 0) acc.push(...gvfield)
                    })
                }
              }

              return err
            }, false)
            if (!diff) acc.push({ page: page.name, field: el.field, type: el.type, error: 'Requerido' })
          }

          return ecc
        }, [])

        if (el.length > 0) acc.push(el)

        // img
        const img = page.images.reduce((icc, img) => {
          const diffImg = sp.images.reduce((mcc, pic) => {
            if (pic.field === img.field) mcc = true

            return mcc
          }, false)
          if (!diffImg) icc.push({ page: page.name, field: img.field, type: 'IMAGE', error: 'Requerido' })

          return icc
        }, [])

        if (img.length > 0) acc.push(...img)

        return acc
      }, [])
    },
    testStory() {
      const template = JSON.parse(this.story.template)

      const acc = []
      const content = this.page.content.find(b => b.block === 'es')
      const templatePage = template.find(s => s.name === this.page.name)
      const elem = content.element.reduce((ecc, el) => {
        if (!templatePage) ecc.push({ page: this.page.name, field: '', type: '', error: 'La página no existe' })
        else {
          const diff = templatePage.content.element.reduce((err, ef) => {
            if (ef.field === el.field && ef.type === el.type) {
              err = true

              if (el.type === 'GROUP') {
                const aelem = el.groupValue
                if (aelem)
                  aelem.forEach((elem, i) => {
                    elem.forEach(gve => {
                      const gvfield = !ef.groupValue
                        ? false
                        : ef.groupValue.reduce((eeg, rees) => {
                            if (gve.field === 'IMG' || (rees.field === gve.field && rees.type === gve.type)) eeg = true

                            return eeg
                          }, false)

                      if (!gvfield)
                        acc.push({ page: this.page.name, field: `${el.field}[ ${i + 1} ]↔️${gve.field}`, type: gve.type, error: 'No Existe' })
                    })
                  })
              }
            }

            return err
          }, false)
          if (!diff) ecc.push({ page: this.page.name, field: el.field, type: el.type, error: 'No Existe' })
        }
        return ecc
      }, [])

      if (elem.length > 0) acc.push(...elem)

      // img
      const img = this.page.images.reduce((icc, img) => {
        const diffImg = templatePage.images.reduce((mcc, pic) => {
          if (pic.field === img.field) mcc = true

          return mcc
        }, false)
        if (!diffImg) icc.push({ page: this.page.name, field: img.field, type: 'IMAGE', error: 'No Existe' })

        return icc
      }, [])

      if (img.length > 0) acc.push(...img)

      return acc
    },
    savePage() {
      if (!this.story.template)
        return this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'No hay template',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })

      const requerido = this.testTemplate()
      const residuo = this.testStory()

      this.tempResult = [...requerido, ...residuo]

      if (this.tempResult.length > 0) {
        const self = this
        return this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Hay campos con error',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          preConfirm() {
            self.modalTemplate = true
          }
        })
      }

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('storyStore/savePage', { storyId: this.$session.story._id, page: { ...this.page, content: this.page.content } })
            .then(() => this.$noti.success(`Página ${this.pageName} guardada`))
            .catch(() => this.$noti.error(`Ha ocurrido un error al guardar`))
        } else this.$noti.error(`Hay errores en el formulario`)
      })
    },
    translate() {
      const self = this
      this.$swal({
        title: `Iniciar la traducción de ${this.pageName}?`,
        text: 'Traduciremos automáticamente todos los elementos de la página',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, traducir',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return self.$store
            .dispatch('storyStore/translate', { page: self.story.page.find(page => page.name === self.pageName) })
            .then(() =>
              self.$swal({
                icon: 'success',
                title: 'Traducción Lista',
                text: 'Ha finalizado la traducción de las páginas, te recomendamos revisar cuidadosamente los resultados.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
            .catch(() =>
              self.$swal({
                icon: 'error',
                title: 'Error de Traducción',
                text: 'Ha ocurrido un error en el servicio de traducción.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
        }
      })
    }

    // reset() {
    // this.content.forEach(cont => cont.element.forEach(el => (el.value = '')))
    // }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
